// const ORM_APIURL = 'https://gv67yxigc9.execute-api.us-east-2.amazonaws.com/icompliance/';
// const FUNC_APIURL = 'https://hvdfmib1fg.execute-api.us-east-2.amazonaws.com/icompliance/';
const ORM_APIURL = 'https://6rd8kuawa1.execute-api.us-east-2.amazonaws.com/icompliance/';
const FUNC_APIURL = 'https://jj07ha999f.execute-api.us-east-2.amazonaws.com/icompliance/';
export const environment = {
  production: false,
  grupoEconomico  : ORM_APIURL + 'SstGrupoEconomicoFunc',
  sociedad        : ORM_APIURL + 'SstSociedadFunc',
  grupoCentro     : ORM_APIURL + 'SstGrupoCentrosFunc',
  centro          : ORM_APIURL + 'SstCentroFunc',
  rolURL          : ORM_APIURL + 'SstRolFunc',
  tipoIdentificacionURL: ORM_APIURL + 'SstTipoIdentificacionFunc',
  moduloURL       : ORM_APIURL + 'SstModuloFunc',
  userURL         : ORM_APIURL + 'SstUsuarioFunc',
  userEntitieRolURL: ORM_APIURL + 'SstUsuarioSociedadRolFunc',
  rolModuloURL    : ORM_APIURL + 'SstRolModuloFunc',
  formatoModuloURL: ORM_APIURL + 'SstFormatoModuloFunc',
  versionURL      : ORM_APIURL + 'SstVersionFunc',
  userSocRolURL   : ORM_APIURL + 'SstUsuarioSociedadRolFunc',
  userEstOrgURL   : ORM_APIURL + 'SstUsuarioEstOrgFunc',
  adjuntosModulos : ORM_APIURL + 'SstAdjuntosModulosFunc',
  parametroURL    : ORM_APIURL + 'SstParametroFunc',
  usuarioAccion   : ORM_APIURL + 'SstUsuarioAccionFunc',
  activEconomURL  : ORM_APIURL + 'SstActividadEconomicaFunc',
  eventoURL       : ORM_APIURL + 'SstEventoFunc',
  normativaURL    : ORM_APIURL + 'SstNormativaFunc',
  consumedStorage : FUNC_APIURL + 'sst_ConsumedStorage',
  S3_URL          : 'https://1soszlmtr1.execute-api.us-east-2.amazonaws.com/icompliance/',
  modulosUserS    : FUNC_APIURL + 'sst_BizModulosUserS', // ok
  dataUser        : FUNC_APIURL + 'sst_BizDataUser', // ok
  moduloTreeURL   : FUNC_APIURL + 'sst_ModulosTree', // linea por eliminar
  // entitieAllURL   : FUNC_APIURL + 'sst_GenGrupoEcoAllOrdered', linea por eliminar
  entitieIdURL    : FUNC_APIURL + 'sst_GenGrupoEcoByIdOrdered', //ok
  modulosByRolURL : FUNC_APIURL + 'sst_ModulosByRol', // ok
  activeModulURL  : FUNC_APIURL + 'sst_ActiveModul', //ok
  usersEntitie    : FUNC_APIURL + 'sst_UsersEntitie ', // ok
  userRols        : FUNC_APIURL + 'sst_UserRols', // ok 
  treeSociedad    : FUNC_APIURL + 'sst_BizTreeSociedad', //ok
  // modulosUser     : FUNC_APIURL + 'sst_BizModulosUser', linea por eliminar
  // numberFiles     : FUNC_APIURL + 'biznumberfiles', linea por eliminar
  modulosAccion   : FUNC_APIURL + 'sst_BizModulosAcc', //ok
  deleteEstOrg    : FUNC_APIURL + 'sst_BizDeleteEstOrg', //ok 
  verifyExist     : FUNC_APIURL + 'sst_BizVerifyExist', //ok
  saveUserWithoutContract: FUNC_APIURL + 'sst_BizSaveUserWithoutContract', //ok
  modulosbyparent : FUNC_APIURL + 'sst_BizModulosByParent',
  userChangeKey   : FUNC_APIURL + 'sst_UserChangeKey',
};

export const AWSInfo = {        // dev
  s3Bucket: 'dev.icompliance.files',
  logoPortal: 'dev.icompliance.files/compliance-logo/compliance-logo-141.png',
  fileTerms : 'dev.icompliance.files/compliance-logo/230111 iCompliance Términos y Condiciones de Uso.pdf'
};

export const cognitoConfig = {  // dev
  userPool: {
      UserPoolId: 'us-east-2_YDTfH7SBL',
      ClientId: '40ldja99an3mgq21nnjl02qhea',
      region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:133e5930-e40a-41db-9868-6d1c391d9773'
};

export const teletrabajo = {
  modalityPresencial  : 'Presencial',
  modalityTeletrabajo : 'Teletrabajo',
  tiles               : [37,38,41,39]
};

export const gestion = {
  frontSST  : 1,
  default   : 1,
  supervisor : [16,36,34,41,45,141,115,117,119,120,121,122,123,124,130,134,135,136,
    50,114,118,113,125,129,131,132,133,137,138,139,140,116,126,127,128]
};

export const personalSalud = 24;

export const permission = {
  edit  : 1,
  view  : 2,
  SO    : 13,
  arrTilesSO    : [56,58,59],
  onlyViewRoles : [25,32,69,29,68,26,30,70],
};